function Trippy() {
    return ( 
        <div id="trippy-bkg">
            <div class="box yellow"></div>
            <div class="box turquoise"></div>
            <div class="box black"></div>
            <div class="box pink"></div>
        </div>
     );
}

export default Trippy